<template>
  <div class="container">
    <div class="about-library">
      <h1>About the Library</h1>
      <p>Welcome to a new style of library. Because... 2020 happened. It’s not your usual online library, where you’re on your own. We stick together because reading together is a joy. Together we escape into outstanding books.</p>
      <p>I read many books on request, and many I choose as well. Longer books are read daily over time. Picture books are screen shared as eBooks, or via a document camera.</p>
      <p>Our “remote library” began 3/18/2020, soon after our county went to lockdown. Student listeners and I tuned in every weekday, even through summer, because staying home flattens the curve.</p>
      <p>This website was built by my daughter, Jane Goldsmith.</p>
      <p>To respect copyright, no recordings are made. Publishers are being generous through the pandemic, but copyright matters. Please do not record as we read.</p>
      <p>Libraries are gloriously free. I’ll teach you how to use them: that’s power! I love that model, and aim to continue it.</p>
    </div>
    <div class="about-amy">
      <div class="text">
      <h1>About Amy</h1>
      <img src="../assets/amyphoto.jpg" alt="Photo of Amy at Keukenhof in the Netherlands">
      <p>Amy Goldsmith was librarian at Daves Avenue School in Los Gatos, CA for 18 years.
      It is a dream job. The library has been made beautiful and sturdy over time thanks to the effort of a remarkable number of people. Thank you all for your teamwork, and the honor of a lifetime. Thank you Florence Adamson, who was the librarian for 20 years prior.</p>

      <p>While at Daves, Mrs. G earned a master’s degree in library science, her MLIS, graduating from SJSU SLIS in May 2011. That degree represents years of study. The library itself informed much of that study.</p>

      <p>One of Mrs. G’s first papers for her MLIS degree related to support of series books, and led to two columns for the AASL digital publication Knowledge Quest, which are often cited but hard to find. These are now available again here, with permission from the ALA:</p>

      <p><a href="/articles/series-sense.html" target="_blank">"Series Sense" (September/October 2007)</a><br>
      <a href="/articles/why-kids-dont-have-series-books.html" target="_blank">"Why Kids Don't Have Series Books" (January/February 2008)</a><br></p>

      <p>From 1986 to 1992, Amy worked at Apple Computer, and wrote Monitors and ColorPicker. Proudest/most storied effort was completing MacDraw II with Gerard Schutten, Margie Kaptanoglu, and Joel Spiegel. Those drawing tools are now part of Pages.</p>

      <p>Before Apple Amy worked at Wang Labs in MA, and attended Princeton, graduating in 1980. Princeton is lovable, but will be more so when they <a href="https://www.divestprinceton.com/our-letter">divest from fossil fuels</a>.</p>

      <p>Areas of special focus: climate change, Alzheimer’s, LGBTQ, series.</p>

      <p>Thank you students and families, past, present, and future. Each of you is unique and lovable. Please visit the eLibrary so I can cheer you on, or share a book that lets us both step elsewhere. Family photos (which collect everyone I have met separately) are one of my favorite things. <span class="heart">&#10084;</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.container {
  display: block;
}
.about-library, .about-amy {
  max-width: 850px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 1.5rem;
}
p {
  margin-top: 1rem;
  font-size: 1.2rem;
}
img {
  width: 50%;
  object-fit: scale-down;
  float: right;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
span.heart {
  color: red;
}
@media (max-width: 650px) {
  .container {
      padding: 2rem 1rem;
    }
  .about-amy {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  img {
    float: none;
    width: 90%;
  }
}
</style>